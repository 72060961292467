import { ReadLines } from '../engine/import/importerutils.js';
import { AddDiv, CreateDomElement } from '../engine/viewer/domutils.js';
import { ButtonDialog } from './dialog.js';
import { Loc } from '../engine/core/localization.js';

export function ShowOpenUrlDialog(onOk) {
	let dialog = new ButtonDialog();
	let urlsTextArea = CreateDomElement('textarea', 'ov_dialog_textarea');
	let contentDiv = dialog.Init(Loc('Tải lên theo url'), [
		{
			name: Loc('Hủy'),
			subClass: 'outline',
			onClick() {
				dialog.Close();
			},
		},
		{
			name: Loc('Xác nhận'),
			onClick() {
				let urls = [];
				ReadLines(urlsTextArea.value, (line) => {
					urls.push(line);
				});
				dialog.Close();
				onOk(urls);
			},
		},
	]);
	let text = Loc(
		'Tại đây bạn có thể tải các mô hình dựa trên url của chúng. Bạn có thể thêm nhiều dòng hơn nếu mô hình của bạn được tạo từ nhiều tệp.',
	);
	AddDiv(contentDiv, 'ov_dialog_section', text);
	contentDiv.appendChild(urlsTextArea);
	dialog.Open();
	urlsTextArea.focus();
	return dialog;
}
