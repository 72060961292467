import { AddDiv } from '../engine/viewer/domutils.js';
import { ThreeModelLoader } from '../engine/threejs/threemodelloader.js';
import { ShowMessageDialog } from './dialogs.js';
import { ButtonDialog, ProgressDialog } from './dialog.js';
import { AddSvgIconElement } from './utils.js';
import { ImportErrorCode } from '../engine/import/importer.js';
import { Loc } from '../engine/core/localization.js';

export class ThreeModelLoaderUI {
	constructor() {
		this.modelLoader = new ThreeModelLoader();
		this.modalDialog = null;
	}

	LoadModel(inputFiles, settings, callbacks) {
		if (this.modelLoader.InProgress()) {
			return;
		}

		let progressDialog = null;
		this.modelLoader.LoadModel(inputFiles, settings, {
			onLoadStart: () => {
				this.CloseDialogIfOpen();
				callbacks.onStart();
				progressDialog = new ProgressDialog();
				progressDialog.Init(Loc('Đang tải mô hình'));
				progressDialog.Open();
			},
			onFileListProgress: (current, total) => {},
			onFileLoadProgress: (current, total) => {},
			onSelectMainFile: (fileNames, selectFile) => {
				progressDialog.Close();
				this.modalDialog = this.ShowFileSelectorDialog(fileNames, (index) => {
					progressDialog.Open();
					selectFile(index);
				});
			},
			onImportStart: () => {
				progressDialog.SetText(Loc('Đang tải mô hình...'));
			},
			onVisualizationStart: () => {
				progressDialog.SetText(Loc('Hiển thị mô hình'));
			},
			onModelFinished: (importResult, threeObject) => {
				progressDialog.Close();
				callbacks.onFinish(importResult, threeObject);
			},
			onTextureLoaded: () => {
				callbacks.onRender();
			},
			onLoadError: (importError) => {
				progressDialog.Close();
				callbacks.onError(importError);
				this.modalDialog = this.ShowErrorDialog(importError);
			},
		});
	}

	GetModelLoader() {
		return this.modelLoader;
	}

	GetImporter() {
		return this.modelLoader.GetImporter();
	}

	ShowErrorDialog(importError) {
		if (importError.code === ImportErrorCode.NoImportableFile) {
			return ShowMessageDialog(
				Loc('Đã xảy ra lỗi!'),
				Loc('Không tìm thấy tập tin có thể tải được.'),
				null,
			);
		} else if (importError.code === ImportErrorCode.FailedToLoadFile) {
			return ShowMessageDialog(
				Loc('Đã xảy ra lỗi!'),
				Loc('Không thể tải được tệp.'),
				Loc(
					'Máy chủ từ chối thực hiện yêu cầu. Kiểm tra xem url có chính xác không và đảm bảo rằng các yêu cầu CORS được cho phép trên máy chủ từ xa.',
				),
			);
		} else if (importError.code === ImportErrorCode.ImportFailed) {
			return ShowMessageDialog(
				Loc('Đã xảy ra lỗi!'),
				Loc('Không thể tải mô hình.'),
				importError.message,
			);
		} else {
			return ShowMessageDialog(
				Loc('Đã xảy ra lỗi!'),
				Loc('Lỗi không rõ.'),
				null,
			);
		}
	}

	ShowFileSelectorDialog(fileNames, onSelect) {
		let dialog = new ButtonDialog();
		let contentDiv = dialog.Init(Loc('Chọn Model'), [
			{
				name: Loc('Hủy'),
				subClass: 'outline',
				onClick() {
					dialog.Close();
				},
			},
		]);
		dialog.SetCloseHandler(() => {
			onSelect(null);
		});

		let text = Loc(
			'Đã tìm thấy nhiều mô hình có thể nhập được. Chọn mô hình bạn muốn nhập từ danh sách bên dưới.',
		);
		AddDiv(contentDiv, 'ov_dialog_message', text);

		let fileListSection = AddDiv(contentDiv, 'ov_dialog_section');
		let fileList = AddDiv(
			fileListSection,
			'ov_dialog_import_file_list ov_thin_scrollbar',
		);

		for (let i = 0; i < fileNames.length; i++) {
			let fileName = fileNames[i];
			let fileLink = AddDiv(fileList, 'ov_dialog_file_link');
			AddSvgIconElement(fileLink, 'meshes', 'ov_file_link_img');
			AddDiv(fileLink, 'ov_dialog_file_link_text', fileName);
			fileLink.addEventListener('click', () => {
				dialog.SetCloseHandler(null);
				dialog.Close();
				onSelect(i);
			});
		}

		dialog.Open();
		return dialog;
	}

	CloseDialogIfOpen() {
		if (this.modalDialog !== null) {
			this.modalDialog.Close();
			this.modalDialog = null;
		}
	}
}
